const mocks = {
  auth: { 
    POST: [

      // аккаунты 

      { 
        token: "This-is-a-mocked-token",
        username: '9055',
        user_pass: 'lkgjsof',
        status: '20',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'manufacturing_man',
        user_pass: 'gh7kd23',
        status: '23',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'admin_man',
        user_pass: 'dasdg214',
        status: '966',
      },

// дизайнеры

      { 
        token: "This-is-a-mocked-token",
        username: 'Azura',
        user_pass: 'Daedr1c',
        status: '1660543483156 , 1611298923259',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Farida_Siraeva',
        user_pass: 'Kj01JdaQ',
        status: '1653653337197',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'faina',
        user_pass: 'j14heArt',
        status: '1644848510234',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'dima',
        user_pass: '1947saf',
        status: '1625815081116',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Chivieva_Alexandra',
        user_pass: 'fdgh452',
        status: '1613570822840 , 1706799430646',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Наталья Колмакова',
        user_pass: 'BV88Ja',
        status: '1706799430646 , 1613570822840',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'sophiHH',
        user_pass: 'sfd14145',
        status: '1611298923259 , 1660543483156',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Maxim',
        user_pass: 'KJ18aPk',
        status: '1693906709344',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Olesya',
        user_pass: 'Ja092Lfk',
        status: '1644838621083',
      },
      // {
      //   token: "This-is-a-mocked-token",
      //   username: 'Alina',
      //   user_pass: 'WidS344a',
      //   status: '1697613312960 , 1715618977060',
      // },
      // { 
      //   token: "This-is-a-mocked-token",
      //   username: 'Дмитрий Соломин',
      //   user_pass: 'UwEk11a',
      //   status: '1707743237200 , 1697613312960',
      // },
      { 
        token: "This-is-a-mocked-token",
        username: 'Полина Лепкова',
        user_pass: 'kdA1eq2',
        status: '1622560085305',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Виктория Санторская',
        user_pass: 'Lop89Ja',
        status: '1697613289809 , 1723295426324',
      },
      // { 
      //   token: "This-is-a-mocked-token",
      //   username: 'Анастасия Горбань',
      //   user_pass: 'yuFF821',
      //   status: '1722784398537 , 1697613289809',
      // },
      { 
        token: "This-is-a-mocked-token",
        username: 'Ольга Седёлкина',
        user_pass: 'nE66ia',
        status: '1723295426324 , 1697613289809',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Гюнель',
        user_pass: 'jf65RT',
        status: '1694883137051',
      },
    
      { 
        token: "This-is-a-mocked-token",
        username: 'Анна Заднепровская',
        user_pass: 'ksA89Pj',
        status: '1706861422357 , 1706861416463',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Лолита',
        user_pass: 'Unz44Ya',
        status: '1706861416463 , 1706861422357',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Дорошенко Екатерина',
        user_pass: 'AAm667',
        status: '1709295384179',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Александр Катаев',
        user_pass: '554YtN',
        status: '1713279326086',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Иванченко Екатерина',
        user_pass: 'kd99Jui',
        status: '1713279358769 , 1721201198827',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Александра Грызова',
        user_pass: '19KyRRf',
        status: '1715618977060 , 1720862536119',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Бартенева Анна',
        user_pass: 'ka990Kj',
        status: '1716903108552',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Водолазская Юлия',
        user_pass: 'pD91Ja',
        status: '1717074524720',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Даша Холодкова',
        user_pass: 'ks017AD',
        status: '1694883142326',
      },
      { 
        token: "This-is-a-mocked-token",
        username: 'Александра Павлова',
        user_pass: 'uy7326Qer',
        status: '1720862536119 , 1715618977060',
      },
    //  { 
    //     token: "This-is-a-mocked-token",
    //     username: 'Волошина Карина',
    //     user_pass: '5447JhFa',
    //     status: '1720876936015 , 1697613289809',
    //   },
      { 
        token: "This-is-a-mocked-token",
        username: 'Ирина Крылова',
        user_pass: 'amO8s11',
        status: '1721201198827 , 1713279358769',
      }
      ,
      { 
        token: "This-is-a-mocked-token",
        username: 'Колесова Анна',
        user_pass: 'NwA246a',
        status: '1721218523154',
      }, 
    
      
    //  { 
    //     token: "This-is-a-mocked-token",
    //     username: 'Сапронова Юния',
    //     user_pass: '9932KppH',
    //     status: '1720876940481 , 1713279358769',
    //   },
    
      // { 
      //   token: "This-is-a-mocked-token",
      //   username: 'Григоренко Наталья',
      //   user_pass: 'Xk881a',
      //   status: '1717074535182 , 1717074541099',
      // },
      // { 
      //   token: "This-is-a-mocked-token",
      //   username: 'Ефимова Любовь',
      //   user_pass: 'Uy66TRe',
      //   status: '1717074541099 , 1717074535182',
      // },
     
    
    ] 
  },
  "user/me": { GET: { 
      username: 'test',
      user_pass: 'test',
    },
  }
};
  
const apiCall = ({ url, data = null, method = 'GET'}) =>
  new Promise((resolve, reject) => {

    setTimeout(() => {
      try {
        if (method == 'GET') {
          resolve(mocks[url][method]);
        }
        for (let i = 0, l = mocks[url][method].length; i < l; i++) {
          if (mocks[url][method][i].username == data.username && mocks[url][method][i].user_pass == data.password
          ) {
            resolve(mocks[url][method][i]);
          } 
        }
      } catch (err) {
        reject(new Error(err));
      }
    }, 1000);
  });
  
  export default apiCall;